import get from 'lodash/get';

class Rule {
  constructor() {
    this.key = null;
    this.value = null;
    this.probability = null;
    this.minCumulative = null;
    this.maxCumulative = null;
  }

  initFromString(string) {
    const ruleData = string.split(/ |=/);

    return this
      .withProbability(get(ruleData, 2, false) ? parseFloat(ruleData[2]) : 1.0)
      .withKey(ruleData[0])
      .withValue(ruleData[1])
      .setMaxCumulative(0.0)
      .setMinCumulative(0.0);
  }

  withProbability(probability) {
    this.probability = probability;
    return this;
  }

  withKey(key) {
    this.key = key;
    return this;
  }


  withValue(value) {
    this.value  = value;
    return this;
  }

  setMaxCumulative(maxCumulative) {
    this.maxCumulative = maxCumulative;
    return this;
  }

  setMinCumulative(minCumulative) {
    this.minCumulative = minCumulative;
    return this;
  }

}

export default Rule;