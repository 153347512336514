import React, { useState }
from "react";
import md5 from 'md5';
import get from 'lodash/get';

import Layout from "../components/layout";
import SEO from "../components/seo";
import LSystemForm from "../components/LSystemForm";
import Visualizer from "../components/Visualizer";

import LexicalEngine from "../lexical-engine";


function IndexPage() {
  const [angle, setAngle] = useState(0);
  const [processingTime, setProcessingTime] = useState(0);
  const [leafsNumber, setLeafsNumber] = useState(0);
  const [hash, setHash] = useState(0);
  const [evaluatedSystem, setEvaluatedSystem] = useState('');

  const handleOnChange = data => {
    console.log(data);

    if (!get(data, 'rules', false)) {
      alert("You need more than one rule in order to process the system");
      return;
    }

    const newHash = md5(`
      ${data.rules.join('')}
      ${data.axiom}
      ${data.iterations}
      ${data.seed}
      ${data.angle}
    `);

    if (newHash === hash) {
      return;
    }

    try {
      const engine = (new LexicalEngine)
        .setSeed(data.seed)
        .setRoot(data.axiom)
        .setRules(data.rules)
        .setIterations(data.iterations);

      const t1 = (new Date).getTime();
      setEvaluatedSystem(engine.execute());
      const t2 = (new Date).getTime();

      evaluatedSystem;
      window.evaluatedSystem = engine.result;

      setProcessingTime(t2 - t1);
      setLeafsNumber(engine.countLeafs());
      setAngle(data.angle);
      setHash(newHash);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="w-full flex" style={{maxHeight:'80vh'}}>
        <div className="w-full h-full md:w-5/12 overflow-y-auto">
          <LSystemForm
            onChange={handleOnChange}
            leafsNumber={leafsNumber}
            processingTime={processingTime}
          />
        </div>
        <div className="w-full md:w-7/12 h-full">
          <Visualizer
            angle={angle}
            system={evaluatedSystem}
          />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
