import React, { useState }from "react";
import { useForm } from 'react-hook-form'
import RulesInputs from './RulesInputs';
import Input from './Input';
import isNumber from 'is-number';

const getExampleRules = () => {
  return [
    { id: 1, value:'A=R-5[B]+6C 0.5' },
    { id: 2, value:'A=R-5*5[B]/6+6C 0.5' },
    { id: 3, value:'C=R+5[B]-6A 0.5' },
    { id: 4, value:'C=R+5/5[B]*6-6A 0.5' },
    { id: 5, value:'B=F 0.18' },
    { id: 6, value:'B=H 0.82' },
    { id: 7, value:'F=R/5[B]*6G 1.0' },
    { id: 8, value:'G=R*5[B]/6F 1.0' },
    { id: 9, value:'H=L[/8J][*9J]J[/9J][*9J] 0.46' },
    { id: 10, value:'H=L[/8J][*9J]J[/9J][*9J]J 0.25' },
    { id: 11, value:'H=L[/8J][*9J]J[/9J][*9J]J[/9J][*9J] 0.08' },
    { id: 12, value:'H=L[/8J][*9J]J[/9J][*9J]J[/9J][*9J]J 0.1' },
    { id: 13, value:'H=L[/8J][*9J]J[/9J][*9J]J[/9J][*9J]J[/9J][*9J]J 0.11' }
  ];
}

function LSystemForm({
  leafsNumber = 0,
  processingTime = 0,
  onChange
}) {
  const [initRules, setInitRules] = useState([]);
  const { register, handleSubmit, control, errors, reset, setValue } = useForm({
    defaultValues: {
      axiom: 'A',
      angle: 10,
      iterations: 1,
      seed: 0,
      rules: [],
    },
  });
  const onSubmit = data => {
    onChange(data);
  };

  const Buttons = ({ backToTop }) => (
    <div>
      <input
        type="submit"
        value="Simulate"
        onClick={handleSubmit(onSubmit)}
        className="mr-4 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
      />
      {!backToTop &&
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          onClick={() => {
            reset();
            setInitRules(getExampleRules());
          }}
        >
          Use Example
        </button>
      }
      {backToTop &&
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
        >
          Back to Top
        </button>
      }
    </div>
  );

  const toUpperCase = (e) => {
    const value = e.target.value;
    e.target.value = isNumber(value) ? value : value.toUpperCase();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="axiom"
        label="Axiom"
        inputRef={register({
          pattern: /^([a-zA-Z1-9+\-*/#@[\]]+)$/,
          required: true,
        })}
        onChange={toUpperCase}
        placeholder="A+AB"
        errors={errors}
        labelClass="mb-4"
      />
      <Input
        type="number"
        name="iterations"
        label="Iterations"
        errors={errors}
        labelClass="mb-4"
        inputRef={register({
          min: { value: 1, message: "Minimum should be 1" },
          max: { value: 25, message: "Maximum should be 25"},
        })}
      />
      <Input
        type="number"
        name="seed"
        label="Seed"
        errors={errors}
        labelClass="mb-4"
        inputRef={register()}
      />
      <Input
        type="number"
        name="angle"
        label="Angle"
        errors={errors}
        labelClass="mb-4"
        inputRef={register({
          min: { value: 0, message: "Minimum should be 0" },
          max: { value: 360, message: "Maximum should be 360"},
        })}
      />
      <Input
        disabled
        type="number"
        labelClass="mb-4"
        name="leafsNumber"
        label="Leafs Number"
        value={leafsNumber}
      />
      <Input
        disabled
        type="number"
        labelClass="mb-4"
        name="processingTime"
        label="Processing Time (miliseconds)"
        value={processingTime}
      />
      <Buttons />
      <div className="w-full my-4">
        <h3 className="text-gray-700 text-xl">Rules</h3>
        <RulesInputs
          control={control}
          errors={errors}
          initRules={initRules}
          setValue={setValue}
        />
      </div>
      <Buttons backToTop/>
    </form>
  );
}

// Layout.propTypes = {
// };

export default LSystemForm;
