import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller } from "react-hook-form";
import isNumber from 'is-number';
import Input from './Input';

function RulesInputs({ control, errors, initRules, setValue }) {
  const [data, setData] = useState(initRules);
  useEffect(() => {
    setData(initRules);
    initRules.forEach((rule, index) => setValue(`rules[${index}]`, rule.value));
  }, [initRules]);

  const append = () => {
    const id = data.length == 0 ? 1 : (data[data.length - 1].id + 1);
    setData([...data, { id, value: '' }])
  };

  const remove = index => {
    setData([...data.slice(0, index), ...data.slice(index + 1)]);
  };

  const toUpperCase = ([e]) => {
    const value = e.target.value;
    return isNumber(value) ? value : value.toUpperCase();
  };

  const AddRemoveButtons = () => (
    <div className="mb-4">
      <button
        type="button"
        onClick={() => append()}
        className="mr-4 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
      >
        + Add
      </button>
      {data.length > 0 && (
        <button
          onClick={() => remove(data.length - 1)}
          className="mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
        >
          - Remove
        </button>
      )}
    </div>
  );

  return (
    <div className='mb-4 mt-2'>
      {data.length > 0 && <AddRemoveButtons />}
      <ul>
        {data.map((item, index) => (
          <li key={item.id} className="flex justify-between mb-4 items-center">
            <Controller
              as={Input}
              name={`rules[${index}]`}
              rules={{
              required: true,
              pattern: /^(([a-zA-Z]){1}(=){1}([a-zA-Z1-9+\-*/#@[\]]+)( [0-1]\.{1}[0-9]+)?)$/,
              }}
              onChange={toUpperCase}
              control={control}
              errors={errors}
              index={index}
              defaultValue={item.value}
              labelClass="w-9/12"
            />
            <button
              onClick={() => remove(index)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              - Delete
            </button>
          </li>
        ))}
      </ul>
      <AddRemoveButtons />
    </div>
  );
}


RulesInputs.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default RulesInputs;