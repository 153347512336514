import { Vector3 } from 'three';

class Transform {
    constructor(position, direction) {
        this.position = new Vector3(position.x, position.y, position.z);
        this.direction = new Vector3(direction.x, direction.y, direction.z);
        this.direction.normalize();
    }

    setPosition(position) {
        this.position.set(position.x, position.y, position.z);
    }

    setDirection(direction) {
        this.direction.set(direction.x, direction.y, direction.z);
        this.direction.normalize();
    }
}

export default Transform;