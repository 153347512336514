import ccount from 'ccount';
import LSystem from './LSystem';

class LexicalEngine {
  constructor() {
    this.root = '';
    this.rules = [];
    this.iterations = 1;
    this.seed = 1;
    this.result = '';
  }

  setRoot(root = '') {
    this.root = root;
    return this;
  }

  setRules(rules = []) {
    this.rules = rules;
    return this;
  }

  setIterations(iterations = 1) {
    this.iterations = iterations;
    return this;
  }

  setSeed(seed = 1) {
    this.seed = seed;
    return this;
  }

  execute() {
    this.result = (new LSystem)
      .withRules(this.rules)
      .withRoot(this.root)
      .simulateFor(this.iterations, this.seed);

    return this.result;
  }

  /**
   * Count the number of leafs
   * @returns {Number}
   */
  countLeafs() {
    return ccount(this.result, 'L');
  }
}

export default LexicalEngine;