import { Vector3 } from 'three';

export function isLetter(character) {
  let regex = /^([A-Z])$/;
  let m = regex.exec(character)
  if(m !== null){
    return true;
  }

  return false;
}

export function isLeaf(character) {
  return character == "H" || character == "J" || character == "L";
}

export function getUnitVector(character) {
  const unitVectors = {
    x: new Vector3(1, 0, 0),
    y: new Vector3(0, 1, 0),
    z: new Vector3(0, 0, 1)
  }

  if (['-', '+'].indexOf(character) >= 0) {
    return unitVectors.z;
  } else if (['*', '/'].indexOf(character) >= 0) {
    return unitVectors.y;
  } else {
    return unitVectors.x;
  }
}

export function getAngleMultiplier(character, nextCharacter) {
  let number = isNaN(nextCharacter) ? 1 : parseInt(nextCharacter);
  return ['+', '/', '@'].indexOf(character) >= 0 ? -number : number;
}


export default {
  isLetter,
  isLeaf,
  getUnitVector,
  getAngleMultiplier,
};