import React from "react";
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  BufferGeometry,
  BufferAttribute,
  LineBasicMaterial,
  VertexColors,
  LineSegments
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { Transform } from '../lexical-engine';
import { isLeaf, isLetter, getAngleMultiplier, getUnitVector } from './utils';
import filter from 'lodash/filter';

class Visualizer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      lastSystem: '',
      lastAngle: 0,
      distances: {
        leaf: 0.5,
        branch: 1
      },
    };

    this.canvas = {
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      stats:null,
      container: null,
      width: null,
      height: null,
      geometry: null,
    }
    this.renderThree = this.renderThree.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.renderize = this.renderize.bind(this);
    this.clearEverything = this.clearEverything.bind(this);
  }

  renderThree() {
    requestAnimationFrame(this.renderThree);
    this.canvas.stats.update();
    this.canvas.controls.update();
  }

  renderScene() {
    this.canvas.renderer.render(this.canvas.scene, this.canvas.camera);
  }

  componentDidMount() {
    this.canvas.width = Math.round(document.getElementById('three-scene').offsetWidth);
    this.canvas.height = document.getElementById('three-scene').parentNode.offsetHeight;

    this.canvas.container = document.getElementById('three-scene');
    this.canvas.scene = new Scene();
    this.canvas.camera = new PerspectiveCamera( 75, this.canvas.width / this.canvas.height, 0.1, 1000 );
    this.canvas.renderer = new WebGLRenderer({antialias: true });
    this.canvas.renderer.setSize(this.canvas.width, this.canvas.height);
    this.canvas.renderer.setClearColor( 0xffffff );
    this.canvas.renderer.setPixelRatio( window.devicePixelRatio );
    this.canvas.container.appendChild(this.canvas.renderer.domElement);

    this.canvas.camera.position.z = 25;

    this.canvas.stats = new Stats();
    this.canvas.stats.domElement.style.position = 'absolute';
    this.canvas.stats.domElement.style.top = '0px';
    this.canvas.container.appendChild(this.canvas.stats.dom);

    this.canvas.controls = new OrbitControls(this.canvas.camera, this.canvas.renderer.domElement);
    this.canvas.controls.addEventListener('change', this.renderScene);
    this.canvas.controls.enableDamping = true;
    this.canvas.controls.dampingFactor = 0.65;
    this.canvas.controls.enableZoom = true;

    this.renderThree();
    this.renderScene();
    console.log(this);

  }

  componentDidUpdate() {
    if (
      this.state.lastSystem !== this.props.system ||
      this.state.lastAngle !== this.props.angle
    ) {
      this.renderize(this.props.system, this.props.angle);
    }
  }

  renderize(system, angle) {
    this.clearEverything();

    this.setState({ ...this.state, lastAngle: angle, lastSystem: system });
    let lastPosition = new Transform({x: 0, y: -10, z: 0}, {x: 0, y: 1, z: 0});
    let transform = new Transform({x: 0, y: -10, z: 0}, {x: 0, y: 1, z: 0});
    let stack = [];
    let character = '';
    let distance = 0;
    let color = null;
    let initialDistance = 2;
    const RAD = Math.PI/180.0;
    let lineNumber = filter(system, (o) => {
      return isLetter(o);
    }).length;
    let linesPositions = new Float32Array(lineNumber * 2 * 3);
    let colors = new Float32Array(lineNumber * 2 * 3);
    let branchColor = {x: 0.41, y: 0.15, z: 0.04};
    let leafColor = {x: 0.13, y: 0.67, z: 0};
    let j = 0;
    this.canvas.geometry = new BufferGeometry();

    for (let i = 0; i < system.length; i++){
      character = system[i];

      if (isLetter(character)) {
        lastPosition.setPosition(transform.position);
        distance = isLeaf(character) ? this.state.distances.leaf : this.state.distances.branch;
        color = isLeaf(character) ? leafColor : branchColor;
        initialDistance = i == 0 ? initialDistance : 1;

        ['x', 'y', 'z'].forEach(coordinate => {
          transform.position[coordinate] += transform.direction[coordinate] * distance * initialDistance;
        });

        ['x', 'y', 'z'].forEach(coordinate => {
          colors[j] = color[coordinate];
          colors[j + 3] = color[coordinate];
          linesPositions[j + 3] = transform.position[coordinate];
          linesPositions[j++] = lastPosition.position[coordinate];
        });
        j += 3;
      } else if (['-', '+', '*', '/', '#', '@'].indexOf(character) >= 0) {
        let multiplier = getAngleMultiplier(character, system[i + 1]);
        transform.direction.applyAxisAngle(getUnitVector(character), multiplier * angle * RAD);
        transform.direction.normalize();
      } else if (character == '[') {
        stack.push(new Transform(
          {x: transform.position.x, y: transform.position.y, z: transform.position.z},
          {x: transform.direction.x, y: transform.direction.y, z: transform.direction.z}
        ));
      } else if (character == ']') {
        transform = stack.pop();
      }
    }

    this.canvas.geometry.setAttribute('position', new BufferAttribute(linesPositions, 3));
    this.canvas.geometry.setAttribute('color', new BufferAttribute(colors, 3));
    this.canvas.geometry.computeBoundingSphere();

    var material = new LineBasicMaterial( {
        vertexColors: VertexColors,
        linewidth: 2
    } );

    let mesh = new LineSegments( this.canvas.geometry, material);

    this.canvas.scene.add(mesh);
    this.renderScene();
  }

  clearEverything() {
    if (this.canvas.geometry != null) {
      this.canvas.geometry.dispose();
    }

    this.canvas.scene.children.forEach(object => {
      this.canvas.scene.remove(object);
    });

    this.canvas.scene = null;
    this.canvas.scene = new Scene();
    //this.controls.reset();
  }

  render() {
    return (
      <div id="three-scene" className="w-full relative">
        {/* <div class="visualizer is-overlay" v-show="loading">
        </div> */}
      </div>
    )
  }
}

export default Visualizer;