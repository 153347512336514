import { uniq, sum, head } from 'lodash';
import seedrandom from 'seedrandom';
import isNumber from 'is-number';

import Rule from './Rule';

class LSystem {
  constructor() {
    this.rules = [];
    this.root = null;
  }

  /**
   * Create the rules collection from string array.
   * @param {Array} rules
   * @returns {this}
   */
  withRules(rules = []) {
    this.rules = rules.map(ruleString => {
      this.checkIfMalformedRuleString(ruleString);
      return (new Rule).initFromString(ruleString);
    });

    this.setRulesCumulativeProbabilityDistributionDomains();
    return this;
  }

  /**
   * Sets the system root
   * @param {String} root
   * @returns {this}
   */
  withRoot(root) {
    this.root = root;
    return this;
  }

  /**
   * Simulates the system for the given iterations
   * @param {Number} iterations
   * @param {Number} seed
   * @returns {String}
   */
  simulateFor(iterations, seed = 0) {
    let result = this.root;
    const rnd = seedrandom(`${seed}`);

    for (let i = 0; i < iterations; i++) {
      result = result.split('').map(character => {
        if (this.isOperatorOrNumber(character)) {
          return character;
        }

        const randomNumber = rnd();
        const rule = head(this.rules.filter(rule => {
          return rule.key === character
            && rule.minCumulative <= randomNumber
            && rule.maxCumulative >= randomNumber;
        }));

        return !rule ? character : rule.value;
      }).join('');
    }

    return result;
  }
  /**
   * Sets the minCumulative and maxCumulative
   * attributes for each rule depending on
   * the probabilities from all the rules
   * @returns {void}
   */
  setRulesCumulativeProbabilityDistributionDomains() {
    uniq(this.rules.map(rule => (rule.key))).forEach(group => {
      const probabilities = this.rulesProbabilitiesFromGroup(group);

      this.rules = this.rules.map((rule, ruleKey) => {
        const maxProbability = this.maxCumulativeProbabiliteFrom(
          probabilities,
          group,
          rule,
          ruleKey
        );

        if (maxProbability - 1.0 > 0.0001) {
          throw new Error('Probability Exception, exceeds 1.0');
        }

        rule.setMaxCumulative(maxProbability);
        rule.setMinCumulative(maxProbability - rule.probability);
        return rule;
      });
    });
  }

  /**
   * Obtains a collection from a group of rules
   * @param {String} group
   * @return {Array}
   */
  rulesProbabilitiesFromGroup(group) {
    return this.rules
      .filter(rule => (rule.key === group))
      .map(rule => (rule.probability));
  }
  /**
   * Returns the total value of the cumulative probability
   * of a rule from a group
   * @param {Array} probabilities
   * @param {String} group
   * @param {Rule} rule
   * @param {String} ruleKey
   * @return {Number}
   */
  maxCumulativeProbabiliteFrom(probabilities, group, rule, ruleKey) {
    return sum(probabilities.filter((probability, probKey) => {
      return rule.key === group && probKey <= ruleKey;
    })) + rule.maxCumulative;
  }

  /**
   * Checks if the character is an operator or a number
   * @param {String} character
   * @returns {Boolean}
   */
  isOperatorOrNumber(character) {
    const operators = ['+', '-', '*', '/', '#', '@', '[', ']'];
    return operators.includes(character) || isNumber(character);
  }

  /**
   * Checks if a string rule is malformed
   * @param {String} ruleString
   * @returns {void}
   */
  checkIfMalformedRuleString(ruleString) {
    if (ruleString.match(
      /([A-Z]){1}(=){1}([A-Z1-9+\-*/#@[\]]+)( )?([0-9]*\.{1}[0-9]+)?/
    ) === null) {
      throw new Error('Malformed Rule');
    }
  }
}

export default LSystem;