import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

const getErrorMessage = error => {
  if (error.message !== '') {
    return error.message;
  }

  switch (error.type) {
    case 'required':
      return 'This field is required';
    case 'pattern':
      return 'This field doesn\'t have the required pattern';
    default:
      break;
  }
};

function Input({
  label,
  inputRef,
  errors,
  index,
  className = '',
  labelClass = '',
  ...rest
}) {
  const props = { ...rest };
  if (inputRef) {
    props.ref = inputRef;
  }

  let error = null;
  if (rest.name.indexOf('[') > -1 && index) {
    const tempName = rest.name.substr(0, rest.name.indexOf('['));
    error = get(errors, `${tempName}.${index}`);
  } else {
    error = get(errors, rest.name);
  }

  return  (
    <label className={`block ${labelClass}`}>
        { label && (<span className="text-gray-700">{label}</span>)}
        <input
          {...props}
          className={`
            form-input
            block
            w-full
            ${label ? 'mt-1' : ''}
            ${error ? 'border-red-600 focus:border-red-600' : ''}
            ${className}
          `}
        />
        {error && (
          <span className="text-xs font-semibold text-red-600">
            {getErrorMessage(error)}
          </span>
        )}
      </label>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  inputRef: PropTypes.any,
  errors: PropTypes.object,
  index: PropTypes.number,
}

export default Input;